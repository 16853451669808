import React from "react"

// Libraries
import { StaticImage } from "gatsby-plugin-image"

// Components
import Seo from "../components/seo"
import Header from "../components/header"
import Hero from "../components/hero"
import FormLanding from "../components/form-landing"
import BodyLanding from "../components/body-landing"
import TopBullets from "./screens/clinician-patient-connection/top-bullets"
import FormContainer from "../components/form-container"

const FORM_ID = process.env.GATSBY_FORM_LANDING_CLIENT_PATIENT_CONNECTION

const EaseLabBacklog = () => {
	return (
		<main>
			<Header />
			<Hero title="Minutes Matter">
				<StaticImage
					width={1300}
					loading="eager"
					src="../assets/images/clinician-patient-connection/hero.jpg"
					className="restrictImage"
					alt="Clinician and patient split screen"
				/>
			</Hero>
			<FormContainer>
				<TopBullets />
				<FormLanding
					wordpressFormID={FORM_ID}
					pageSlug="/clinician-patient-connection-thank-you/"
				/>
			</FormContainer>
			<BodyLanding landingPage="clinician-patient-connection" />
		</main>
	)
}

export default EaseLabBacklog

export const Head = () => (
	<Seo
	  title="Minutes Matter"
	  description="Testing with Visby Medical represents an untapped growth opportunity for your urgent care."
	  recaptcha
	  />
  )
  